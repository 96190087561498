import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { CSSProperties } from "@material-ui/core/styles/withStyles"

type Props = {
  width?: string
  numberOfLines?: number
  overflowWrap?: CSSProperties["overflowWrap"]
}

const useTruncateStyles = makeUseStyles({
  truncateMultipleLines: (props: Props) => ({
    display: "-webkit-box",

    maxWidth: props.width!,

    WebkitLineClamp: props.numberOfLines!,

    WebkitBoxOrient: "vertical",

    overflow: "hidden",

    overflowWrap: props.overflowWrap ?? "break-word",
  }),
  truncateLine: (props: Props) => ({
    display: "inline-block",

    maxWidth: props.width!,

    overflow: "hidden",

    verticalAlign: "top",

    whiteSpace: "nowrap",

    textOverflow: "ellipsis",

    overflowWrap: props.overflowWrap ?? "break-word",
  }),
})

export default useTruncateStyles
