import { getContrastRatio, Theme } from "@material-ui/core"

namespace ColorUtils {
  export function isTransparent(color: string) {
    // check alpha hex to see if color is transparent enough
    if (!/^#[0-9A-F]{4}([0-9A-F]{4})?$/i.test(color)) return false
    const alphaHex = color.length === 5 ? color.slice(-1).repeat(2) : color.slice(-2)
    const alpha = parseInt(alphaHex, 16) / 255
    return alpha < 0.4
  }

  export function getContrastColor(
    theme: Theme,
    badgeColor: string,
    isSideBar?: boolean
  ) {
    // if transparent, contrast color is based on theme
    if (isTransparent(badgeColor)) {
      if (isSideBar)
        return theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[200]
          : theme.palette.groovy.neutral[500]
      return theme.palette.type === "dark" ? "white" : theme.palette.groovy.grey[700]
    }

    // otherwise, compare with badge color
    const isDark = getContrastRatio("#ffffff", badgeColor) > 3
    return isDark ? "white" : theme.palette.groovy.grey[700]
  }

  // Ensure that the color is a valid hex color
  export function makeSafeHex(color: string | null | undefined) {
    const validColorCode =
      /(^#[0-9A-F]{3}$)|(^#[0-9A-F]{4}$)|(^#[0-9A-F]{6}$)|(^#[0-9A-F]{8}$)/i

    // return this arbitrary white if color is undefined or invalid
    if (!color || !validColorCode.test(color)) return "#ffffff00"
    if (color.startsWith("#")) return color
    return `#${color}`
  }
}

export default ColorUtils
