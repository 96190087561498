/* eslint-disable local-rules/disco-unused-classes */

import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
const useSkeletonAnimationClasses = makeUseStyles({
  pulse: {
    animation: "MuiSkeleton-keyframes-pulse 1.5s ease-in-out 0.5s infinite",
  },
})

export default useSkeletonAnimationClasses
