/* eslint-disable local-rules/disco-unused-classes */

import { TextVariantWithModifiers } from "@assets/style/appMuiTheme"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { Theme } from "@material-ui/core"

/**
 *  Allow components to adopt the typography variants in https://www.figma.com/file/WTaQmtUBbkv6t1WS3umNTw/DISCO-Design-System?node-id=124%3A6274
 *
 * @example
 *  // The component must specify the typography variant prop
 *  type MyComponentProps = {
 *      typography: TextVariantWithModifiers
 *  }
 *
 *  // In the component file
 *  function MyTextComponent() {
 *      const typographyStyles = useTypographyStyles()
 *      return <div className={typographyStyles[typography]}> ...
 *  }
 *
 *  <MyComponent typography={"body-xs-500"}>
 *
 */
function makeTypographyStyles() {
  return makeUseStyles<TextVariantWithModifiers>((theme) => typographyStyles(theme))
}
export function typographyStyles(theme: Theme): Record<TextVariantWithModifiers, any> {
  return {
    "heading-xxl": theme.typography["heading-xxl"],
    "heading-xxl-500": {
      ...theme.typography["heading-xxl"],
      ...theme.typography.modifiers.fontWeight[500],
    },
    "heading-xxl-600": {
      ...theme.typography["heading-xxl"],
      ...theme.typography.modifiers.fontWeight[600],
    },
    "heading-xxl-700": {
      ...theme.typography["heading-xxl"],
      ...theme.typography.modifiers.fontWeight[700],
    },
    "heading-xl": theme.typography["heading-xl"],
    "heading-xl-500": {
      ...theme.typography["heading-xl"],
      ...theme.typography.modifiers.fontWeight[500],
    },
    "heading-xl-600": {
      ...theme.typography["heading-xl"],
      ...theme.typography.modifiers.fontWeight[600],
    },
    "heading-xl-700": {
      ...theme.typography["heading-xl"],
      ...theme.typography.modifiers.fontWeight[700],
    },
    "heading-lg": theme.typography["heading-lg"],
    "heading-lg-500": {
      ...theme.typography["heading-lg"],
      ...theme.typography.modifiers.fontWeight[500],
    },
    "heading-lg-600": {
      ...theme.typography["heading-lg"],
      ...theme.typography.modifiers.fontWeight[600],
    },
    "heading-lg-700": {
      ...theme.typography["heading-lg"],
      ...theme.typography.modifiers.fontWeight[700],
    },
    "heading-md": theme.typography["heading-md"],
    "heading-md-500": {
      ...theme.typography["heading-md"],
      ...theme.typography.modifiers.fontWeight[500],
    },
    "heading-md-600": {
      ...theme.typography["heading-md"],
      ...theme.typography.modifiers.fontWeight[600],
    },
    "heading-md-700": {
      ...theme.typography["heading-md"],
      ...theme.typography.modifiers.fontWeight[700],
    },
    "heading-sm": theme.typography["heading-sm"],
    "heading-sm-500": {
      ...theme.typography["heading-sm"],
      ...theme.typography.modifiers.fontWeight[500],
    },
    "heading-sm-600": {
      ...theme.typography["heading-sm"],
      ...theme.typography.modifiers.fontWeight[600],
    },
    "heading-sm-700": {
      ...theme.typography["heading-sm"],
      ...theme.typography.modifiers.fontWeight[700],
    },
    "heading-xs": theme.typography["heading-xs"],
    "heading-xs-500": {
      ...theme.typography["heading-xs"],
      ...theme.typography.modifiers.fontWeight[500],
    },
    "heading-xs-600": {
      ...theme.typography["heading-xs"],
      ...theme.typography.modifiers.fontWeight[600],
    },
    "heading-xs-700": {
      ...theme.typography["heading-xs"],
      ...theme.typography.modifiers.fontWeight[700],
    },
    "body-lg": theme.typography["body-lg"],
    "body-lg-500": {
      ...theme.typography["body-lg"],
      ...theme.typography.modifiers.fontWeight[500],
    },
    "body-lg-600": {
      ...theme.typography["body-lg"],
      ...theme.typography.modifiers.fontWeight[600],
    },
    "body-lg-700": {
      ...theme.typography["body-lg"],
      ...theme.typography.modifiers.fontWeight[700],
    },
    "body-lg-uppercase": {
      ...theme.typography["body-lg"],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-lg-500-uppercase": {
      ...theme.typography["body-lg"],
      ...theme.typography.modifiers.fontWeight[500],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-lg-600-uppercase": {
      ...theme.typography["body-lg"],
      ...theme.typography.modifiers.fontWeight[600],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-lg-700-uppercase": {
      ...theme.typography["body-lg"],
      ...theme.typography.modifiers.fontWeight[700],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-md": theme.typography["body-md"],
    "body-md-500": {
      ...theme.typography["body-md"],
      ...theme.typography.modifiers.fontWeight[500],
    },
    "body-md-600": {
      ...theme.typography["body-md"],
      ...theme.typography.modifiers.fontWeight[600],
    },
    "body-md-700": {
      ...theme.typography["body-md"],
      ...theme.typography.modifiers.fontWeight[700],
    },
    "body-md-uppercase": {
      ...theme.typography["body-md"],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-md-500-uppercase": {
      ...theme.typography["body-md"],
      ...theme.typography.modifiers.fontWeight[500],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-md-600-uppercase": {
      ...theme.typography["body-md"],
      ...theme.typography.modifiers.fontWeight[600],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-md-700-uppercase": {
      ...theme.typography["body-md"],
      ...theme.typography.modifiers.fontWeight[700],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-sm": {
      ...theme.typography["body-sm"],
    },
    "body-sm-500": {
      ...theme.typography["body-sm"],
      ...theme.typography.modifiers.fontWeight[500],
    },
    "body-sm-600": {
      ...theme.typography["body-sm"],
      ...theme.typography.modifiers.fontWeight[600],
    },
    "body-sm-700": {
      ...theme.typography["body-sm"],
      ...theme.typography.modifiers.fontWeight[700],
    },
    "body-sm-uppercase": {
      ...theme.typography["body-sm"],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-sm-500-uppercase": {
      ...theme.typography["body-sm"],
      ...theme.typography.modifiers.fontWeight[500],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-sm-600-uppercase": {
      ...theme.typography["body-sm"],
      ...theme.typography.modifiers.fontWeight[600],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-sm-700-uppercase": {
      ...theme.typography["body-sm"],
      ...theme.typography.modifiers.fontWeight[700],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-xs": theme.typography["body-xs"],
    "body-xs-500": {
      ...theme.typography["body-xs"],
      ...theme.typography.modifiers.fontWeight[500],
    },
    "body-xs-600": {
      ...theme.typography["body-xs"],
      ...theme.typography.modifiers.fontWeight[600],
    },
    "body-xs-700": {
      ...theme.typography["body-xs"],
      ...theme.typography.modifiers.fontWeight[700],
    },
    "body-xs-uppercase": {
      ...theme.typography["body-xs"],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-xs-500-uppercase": {
      ...theme.typography["body-xs"],
      ...theme.typography.modifiers.fontWeight[500],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-xs-600-uppercase": {
      ...theme.typography["body-xs"],
      ...theme.typography.modifiers.fontWeight[600],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
    "body-xs-700-uppercase": {
      ...theme.typography["body-xs"],
      ...theme.typography.modifiers.fontWeight[700],
      ...theme.typography.modifiers.textTransform.uppercase,
    },
  }
}
export default makeTypographyStyles
