/* eslint-disable local-rules/disco-unused-classes */

import { Spacing } from "@assets/style/appMuiTheme"
import makeUseStyles from "@assets/style/util/makeUseStyles"

type StyleProps = Exclude<Spacing, "padding" | "margin"> & {
  padding?: number
  margin?: number
}

/**
 * Allow margin and padding to be passed in as props.
 * Padding top/bottom/right/left overrides the default padding individually.
 * Same applies to margin.
 *
 * Default padding and margin are required, the rest are optional
 * */
export default function makeSpacingStyles() {
  return makeUseStyles((theme) => ({
    root: (props: StyleProps) => ({
      padding: theme.spacing(
        props.paddingTop ?? props.padding ?? 0,
        props.paddingRight ?? props.padding ?? 0,
        props.paddingBottom ?? props.padding ?? 0,
        props.paddingLeft ?? props.padding ?? 0
      ),
      margin: theme.spacing(
        props.marginTop ?? props.margin ?? 0,
        props.marginRight ?? props.margin ?? 0,
        props.marginBottom ?? props.margin ?? 0,
        props.marginLeft ?? props.margin ?? 0
      ),
    }),
  }))
}
