import { Theme } from "@material-ui/core/styles"
import { Classes } from "jss"
import { useMemo } from "react"
import { createUseStyles, Styles } from "react-jss"

/** The callback for useStyles with required props when Props type is defined */
type UseStylesFn<C extends string, Props = undefined> = (
  data?: UseStylesProps<Props>
) => Classes<C>
type UseStylesProps<Props> = Props extends undefined
  ? { theme?: Theme } | undefined
  : Props & { theme?: Theme }

function makeUseStyles<C extends string = string, Props = undefined>(
  styles: Styles<C, Props, Theme> | ((theme: Theme) => Styles<C, Props, undefined>),
  options = {}
) {
  const useStyles = createUseStyles(styles, options) as UseStylesFn<C, Props>

  // Memoize useStyles props so stylesheet isn't rebuilt on every render
  // See https://github.com/cssinjs/jss/issues/1510
  return (props?: UseStylesProps<Props>) => {
    const dependencies = props ? Object.values(props) : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const stylesProps = useMemo(() => props, dependencies)
    return useStyles(stylesProps)
  }
}

export default makeUseStyles
