import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import classNames from "classnames"

/** Combine two useStyles classes objects using classNames */
export default function mergeClasses<T extends string>(
  ...toMerge: (Partial<ClassNameMap<T>> | null | undefined)[]
): ClassNameMap<T> {
  const merged: Partial<ClassNameMap<T>> = {}
  for (const classes of toMerge) {
    if (!classes) continue
    for (const [key, className] of Object.entries<string>(classes as ClassNameMap<T>)) {
      merged[key as T] = classNames(merged[key as T], className)
    }
  }
  return merged as ClassNameMap<T>
}
