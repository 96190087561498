import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"

type Props = Pick<StyleProps, "disabled" | "hideOnTouchscreen"> & {
  hideWithDisplay?: {
    stylesWhenShown: {
      display: string
    }
  }
}

/**
 * Returns a classes.showable that is hidden until classes.hoverable is hovered.
 */
function useShowOnHoverStyles(props: Props = {}) {
  const [showable] = useState(() => `showOnHover-${uuidv4()}`)
  const [hidable] = useState(() => `hideOnHover-${uuidv4()}`)
  const { hoverable } = useStyles({ showable, hidable, ...props })
  return { showable, hidable, hoverable }
}

export default useShowOnHoverStyles

interface StyleProps {
  showable: string
  hidable: string
  hideWithDisplay?: {
    stylesWhenShown: {
      display: string
    }
  }
  disabled?: boolean
  hideOnTouchscreen?: boolean
}

const useStyles = makeUseStyles({
  hoverable: ({
    showable,
    hidable,
    hideWithDisplay,
    disabled,
    hideOnTouchscreen,
  }: StyleProps) => {
    // These styles render/unrder the element using the property 'display'
    if (hideWithDisplay) {
      return {
        [`& .${showable}`]: {
          display: "none",
          // Touchscreen device without hover - always show
          ...styleIf(!hideOnTouchscreen && !disabled, {
            "@media (hover: none)": {
              ...hideWithDisplay.stylesWhenShown,
            },
          }),
        },
        ...styleIf(!disabled, {
          // Regular show on hover
          [`&:hover .${showable}`]: {
            ...hideWithDisplay.stylesWhenShown,
          },
        }),

        [`& .${hidable}`]: {
          // Touchscreen device without hover - always hide
          ...styleIf(!hideOnTouchscreen && !disabled, {
            "@media (hover: none)": {
              display: "none",
            },
          }),
        },
        ...styleIf(!disabled, {
          // Regular hide on hover
          [`&:hover .${hidable}`]: {
            display: "none",
          },
        }),
      }
    }

    // These styles show/hide the element using the property 'visibility'
    return {
      [`& .${showable}`]: {
        visibility: "hidden",
        // Touchscreen device without hover - always show
        ...styleIf(!hideOnTouchscreen && !disabled, {
          "@media (hover: none)": {
            visibility: "visible",
          },
        }),
      },
      ...styleIf(!disabled, {
        // Regular show on hover
        [`&:hover .${showable}`]: {
          visibility: "visible",
        },
      }),

      [`& .${hidable}`]: {
        // Touchscreen device without hover - always hide
        ...styleIf(!hideOnTouchscreen && !disabled, {
          "@media (hover: none)": {
            visibility: "hidden",
          },
        }),
      },
      ...styleIf(!disabled, {
        // Regular hide on hover
        [`&:hover .${hidable}`]: {
          visibility: "hidden",
        },
      }),
    }
  },
})
